<template>
  <v-dialog v-model="dialog" scrollable max-width="1400">
    <v-card :loading="loading">
      <v-system-bar window>
        {{ title }} {{ name }}<v-spacer /><v-btn icon @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-system-bar
      >

      <v-card-text class="ma-0 pa-0">
        <v-container>
          <v-row>
            <v-col>
              <v-card outlined class="mb-4">
                <v-system-bar>Anschrift</v-system-bar>
                <v-card-text v-html="item.address"></v-card-text>
              </v-card>
              <v-card outlined class="mb-4">
                <v-system-bar>Domäne</v-system-bar>
                <v-card-text>
                  {{ item.domain }}
                </v-card-text>
              </v-card>
              <v-card outlined class="mb-4">
                <v-system-bar>Kommentar</v-system-bar>
                <v-card-text>
                  {{ item.comments }}
                </v-card-text>
              </v-card>
              <v-card outlined class="mb-4">
                <v-system-bar>Kontakt</v-system-bar>

                <v-chip-group class="px-4 py-1">
                  <PhoneChip v-if="item.phoneHome" :value="item.phoneHome" />
                  <PhoneChip
                    v-if="item.phoneMobile"
                    :value="item.phoneMobile"
                    icon="mdi-cellphone"
                  />
                  <PhoneChip
                    v-if="item.phoneWork"
                    :value="item.phoneWork"
                    icon="mdi-factory"
                  />
                  <EmailChip v-if="item.email1" :value="item.email1" />
                  <EmailChip v-if="item.email2" :value="item.email2" />
                </v-chip-group>
              </v-card>
              <v-card outlined>
                <v-system-bar>Weitere Angaben</v-system-bar>
                <v-card-text class="px-0 py-2">
                  <v-row dense>
                    <v-col cols="4" class="text-right">
                      <strong>Beruf:</strong></v-col
                    >

                    <v-col>
                      {{ item.profession ? item.profession : "—" }}</v-col
                    >
                  </v-row>
                  <v-row dense>
                    <v-col cols="4" class="text-right">
                      <strong>Erhalten:</strong></v-col
                    >

                    <v-col> {{ item.received ? item.received : "–" }}</v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="4" class="text-right">
                      <strong>Privat:</strong></v-col
                    >

                    <v-col> {{ item.privat ? "ja" : "nein" }}</v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="4" class="text-right">
                      <strong>Aktiv:</strong></v-col
                    >

                    <v-col> {{ item.active ? "ja" : "nein" }}</v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined>
                <v-system-bar>Zuteilungen</v-system-bar>
                <v-card-text>
                  <v-timeline>
                    <v-timeline-item
                      v-for="(item, index) in placements"
                      :key="index"
                      :color="color(item)"
                    >
                      <template v-slot:icon>
                        <v-avatar
                          ><PortraitImage :value="item.student"
                        /></v-avatar>
                      </template>
                      <template v-slot:opposite>
                        <span :class="`text-h6 ${color(item)}--text`">
                          <DateSpan
                            v-if="item.startDate && item.endDate"
                            :value="[item.startDate, item.endDate]"
                          ></DateSpan>
                          <span v-else>{{ item.schoolYear.description }}</span>
                        </span>
                      </template>
                      <v-card v-if="item.student">
                        <v-list>
                          <v-list-item v-if="item.student">
                            <v-list-item-content>
                              <v-list-item-title>
                                <PersonName :value="item.student" ext />
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Schüler*in
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <Rating :value="item.ratingStudent" />
                          </v-list-item>
                          <v-list-item v-if="item.teacher">
                            <v-list-item-content>
                              <v-list-item-title>
                                <PersonName :value="item.teacher" ext />
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Lehrkraft
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <Rating :value="item.ratingTeacher" />
                          </v-list-item>
                          <v-list-item v-if="item.ratingInstitution">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ title }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <Rating :value="item.ratingInstitution" />
                          </v-list-item>
                        </v-list>
                        <v-card-text v-if="item.feedback">
                          {{ item.feedback }}
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn text @click="close">Schliessen</v-btn><v-spacer />
        <v-btn
          color="primary"
          text
          :to="{ name: 'PlacementInstitutionEdit', params: { id: item.id } }"
          >Bearbeiten</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import DateSpan from "common/components/DateSpan.vue";

import EmailChip from "common/components/EmailChip.vue";
import PhoneChip from "@/components/PhoneChip.vue";
import PortraitImage from "common/components/PortraitImage.vue";
import PersonName from "common/components/PersonName.vue";
import Rating from "./Rating.vue";

export default defineComponent({
  props: ["id"],
  components: {
    DateSpan,

    EmailChip,
    PhoneChip,
    PersonName,
    PortraitImage,
    Rating,
  },
  data() {
    return {
      dialog: true,
      loading: false,
      item: {},
      placements: [],
    };
  },
  computed: {
    name() {
      return this.item.instName ? this.item.instName : this.item.lastName1;
    },
    title() {
      return this.item.instName ? "Institution" : "Familie";
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$router.push({ name: "PlacementInstitutions" });
      }
    },
  },
  methods: {
    icon1(rating) {
      if (rating && rating.code) {
        return rating.code.replaceAll("+", "<v-icon>mdi-plus</v-icon");
      }
      return "";
    },
    icon2(rating) {
      if (rating && rating.code) {
        return rating.code.replaceAll("+", "<v-icon>mdi-plus</v-icon");
      }
      return "";
    },
    color(item) {
      if (item.student) {
        return "info";
      }
      return "error";
    },
    close() {
      this.dialog = false;
    },
    async fetchData() {
      this.loading = true;

      this.item = await this.apiGet({
        resource: "placement/institution",
        id: this.id,
      });
      const placementsData = await this.apiList({
        resource: "placement/placement",
        query: `institution=${this.id}`,
      });
      this.placements = placementsData.sort((a, b) =>
        a.schoolYear.id > b.schoolYear.id
          ? -1
          : a.schoolYear.id < b.schoolYear.id
          ? 1
          : 0
      );

      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
