<template>
  <v-chip :href="'tel:' + tel">
    <v-icon left>{{ this.icon }}</v-icon>
    {{ value }}
  </v-chip>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "PhoneChip",
  props: {
    value: { type: String, default: "" },
    icon: { type: String, default: "mdi-phone" },
  },
  computed: {
    tel() {
      return this.value.replace(/[\D|+]/g, "");
    },
  },
});
</script>
